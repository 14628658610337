
<div class="d-flex justify-content-between align-items-center w-100 mb-4">

  <h3 class="my-0">Backends</h3>

  <button
    mat-flat-button
    color="primary"
    (click)="addNew()">
    Add backend
</button>

</div>

<div class="table-responsive backend-list">
  <table
    mat-table
    [dataSource]="backendsList"
    class="w-100 borderless">

    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef class="text-nowrap"> Username </th>
      <td mat-cell *matCellDef="let element" class="text-nowrap"> {{element?.username}} </td>
    </ng-container>

    <!-- Backend URL Column -->
    <ng-container matColumnDef="backendURL">
      <th mat-header-cell *matHeaderCellDef> Backend URL </th>
      <td mat-cell *matCellDef="let element" class="text-nowrap"> {{element?.url}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let element" class="text-nowrap ps-3">
        <span class="status {{element.url === activeBackend ? 'connected' : 'disconnected'}} me-1"></span>
      </td>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">

      <th mat-header-cell *matHeaderCellDef class="text-nowrap">Actions</th>

      <td mat-cell *matCellDef="let element;let index=index;let last=last;let first=first" class="text-nowrap">

        <button
          mat-button
          color="primary"
          [disabled]="element.url === activeBackend"
          (click)="switchBackend(element)">
          Switch
        </button>

        <button
          mat-button
          color="primary"
          (click)="copyUrlWithBackend(element.url)">
          Copy URL
        </button>

        <button
          mat-button
          color="warn"
          (click)="removeBackend(element)">
          Remove
        </button>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>
