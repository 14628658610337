
<h3 mat-dialog-title>{{data?.title}}</h3>

<div mat-dialog-content>
  <ng-container
    *ngComponentOutlet="data.component">
  </ng-container>
</div>

<div mat-dialog-actions [align]="'end'">

  <button
    mat-button
    mat-dialog-close>
    Close
  </button>

</div>
