
<h3 mat-dialog-title>New version available! 🎉</h3>

<div mat-dialog-content>
  <p class="mat-body-2">
    If you have unsaved work then please save it and <span class="fw-bold">refresh the page</span>.
  </p>
</div>

<div mat-dialog-actions [align]="'end'">

  <button
    mat-button
    (click)="remindLater()"
    tabIndex="-1">
    Remind me later
  </button>

  <button
    mat-flat-button
    color="primary"
    (click)="reloadPage()">
    Refresh now
  </button>

</div>
