<div class="container-fluid core px-0">

  <div class="row flex-column w-100 no-gutters">

    <div class="col-12 my-auto">
      <div class="row h-100">
        <div class="col-lg-4 col-md-4 col-12">
          <div class="text-center">


                <div _ngcontent-uyl-c60="" class="text-center box-logo" style="background: #fff;
                border-radius: 40px;
                width: max-content;
                padding: 20px 20px 1px 20px;
                margin: 0 auto;
                box-shadow: 0 30px 50px #097154;
                "><a _ngcontent-uyl-c60="" href="https://www.chat4you.ai"><img _ngcontent-uyl-c60="" src="https://www.admin123.chat4you.ai/chat/assets/images/chat4you-logo.svg" alt="Chat4you" width="350"></a><p _ngcontent-uyl-c60="" class="mat-subheading-2 mt-4 title-text"></p></div>

          </div>
        </div>
        <div class="col-lg-8 col-md-8 col-12 my-auto">
          <div class="row justify-content-center h-100 align-items-md-center">
            <div class="col-xl-8 col-sm-10 col-11 pb-md-0 pb-4">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
