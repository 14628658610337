
<div id="navbar" class="d-flex align-items-center justify-content-lg-between mat-elevation-z2" style="background-color:#1C1B21;left:60px; margin-right:50px;">

  <button
    mat-icon-button
    color="primary"
    class="d-lg-none d-block me-2"
    (click)="toggleSidebar()">
    <mat-icon style="color:white">menu</mat-icon>
  </button>

  <a class="me-lg-0 me-auto pointer" style="width:100%" routerLink="/">  

    <img
      src="../../../../assets/images/ainiro-logo-new.svg"
      alt="Chat4you"
      height="20"
      style="display:none  !important"
      class="my-auto d-sm-block d-none">
      <img
      src="https://www.chat4you.ai/wp-content/uploads/2024/04/logo-chat4.png"
      alt="Chat4you"
      height="50"
      style="display:block; margin:auto;"
      class="my-auto d-sm-block">
    <img
      src="../../../../assets/images/icons/logo-180x180.png"
      alt="Chat4you"
      height="50"
      class="my-auto d-sm-none d-block d-none">
  </a>


  <div class="nav-links mx-auto  d-flex flex-lg-column flex-column" [class.open]="sideExpanded">
    <a class="me-lg-0 me-auto pointer" routerLink="/"> <img
      src="https://www.chat4you.ai/wp-content/uploads/2024/04/logo-chat4.png"
      alt="Chat4you"
      height="50"
      class="my-auto d-sm-block d-none" style="margin-top:-70px !important;   padding-left: 5px;">
    </a>
    <img
      src="../../../../assets/images/ainiro-logo-new.svg"
      alt="Chat4you"
      height="20"
      style="display:none !important"
      class="mx-auto d-block d-lg-none mb-4">
      <a
      mat-button
      class="nav-link"
      *ngFor="let item of navLinks"
      [routerLink]="item.url"
      [queryParams]="{ tab: item.name.toLowerCase() }"
      (click)="item.expandable ? null : closeSidebarInSidePanel(item.url)"
      [matMenuTriggerFor]="item.expandable ? subMenu : null"
      [matMenuTriggerData]="item"
      routerLinkActive="active-link"
      [routerLinkActiveOptions]="{exact: item.exact}"
      [class.active-link]="item?.isActive"
      matTooltip="{{ item.name }}"
      matTooltipClass="custom-tooltip"
    >
      <mat-icon class="mobile_color" style="color:white">{{ item.icon }}</mat-icon>
      <span class="mobile" style="color:black" >{{ item.name }}</span>
    </a>
    <p class="mobile" style="padding:20px;" (click)="toggleSidebar()">Chiudi menu</p>

    <mat-menu #subMenu="matMenu">

      <ng-template
        matMenuContent
        let-submenuItems="submenu">

        <a
          mat-menu-item
          [class.warn]="item.color"
          *ngFor="let item of submenuItems"
          [routerLink]="item.disabled ? null : item.url"
          [routerLinkActiveOptions]="{exact: item.exact}"
          routerLinkActive="active-link"
          #isActiveLink="routerLinkActive"
          (click)="getGithubToken(item.name);logout(item.name);closeSidebarInSidePanel(item.url)">
          {{item.name}}
        </a>

      </ng-template>

    </mat-menu>

  </div>

  <div class="d-flex align-items-center">
<!--
    <button
      mat-flat-button
      color="primary"
      class="url-btn"
      (click)="viewBackends()">
      <div class="d-flex flex-nowrap align-items-center ps-1">
        <mat-icon style="color:white">database</mat-icon>
        <p class="my-0 mx-2 text-truncate url-text">{{activeUrl}}</p>
      </div>
    </button>

    <button
      mat-button
      color="primary"
      class="ms-2 url-btn"
      [matMenuTriggerFor]="help_menu">
      <div class="d-flex flex-nowrap align-items-center ps-1">
        <mat-icon style="color:white">question_mark</mat-icon>
      </div>
    </button>

    <mat-menu
      #help_menu="matMenu"
      class="px-4 py-3 m-0 help_menu">
      <span class="text-muted">
        {{help_description}}
      </span>
      <a
        class="read_more_help mt-3"
        mat-button
        target="_blank"
        [href]="help_url">
        Read more ...
      </a>
    </mat-menu>
-->
    <button
      mat-button
      class="menu_trigger"
      #completion_menu_trigger="matMenuTrigger"
      [matMenuTriggerFor]="completion_menu">
    </button>

    <mat-menu
      #completion_menu="matMenu"
      class="px-4 py-3 m-0 help_menu">
      <div class="text-muted" [innerHTML]="completion | marked">
      </div>
    </mat-menu>

  </div>
</div>

<div class="overlay" *ngIf="sideExpanded" (click)="toggleSidebar()"></div>
