
<h3 mat-dialog-title>Generate token</h3>

<div mat-dialog-content>

  <mat-form-field class="w-100 standalone-field">
    <span
      matPrefix
      matTooltip="Username to associate token with"
      class="d-flex flex-nowrap align-items-center justify-content-between me-2">
      <mat-icon>person</mat-icon>
      <span class="text-muted">|</span>
    </span>
    <input
      type="text"
      matInput
      autocomplete="off"
      spellcheck="false"
      [(ngModel)]="data.username">
  </mat-form-field>

  <mat-form-field class="w-100 standalone-field">
    <span
      matPrefix
      matTooltip="Token's expiration date"
      class="d-flex flex-nowrap align-items-center justify-content-between me-2">
      <mat-icon>calendar_month</mat-icon>
      <span class="text-muted">|</span>
    </span>
    <input
      matInput
      [matDatepicker]="picker"
      [(ngModel)]="data.expires"
      autocomplete="off"
      [name]="data.expires"
      [min]="today"
      (click)="picker.open()"
      readonly>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field class="w-100 standalone-field">
    <span
      matPrefix
      matTooltip="Role(s) to associate token with"
      class="d-flex flex-nowrap align-items-center justify-content-between me-2">
      <mat-icon>shield</mat-icon>
      <span class="text-muted">|</span>
    </span>
    <input
      type="text"
      matInput
      [(ngModel)]="data.role">
  </mat-form-field>

  <ng-container *ngIf="token && token!==''">

    <div class="d-flex justify-content-between align-items-center mb-2 mt-3">

      <h3 class="my-0">Token is Ready</h3>

      <button
        mat-button
        color="primary"
        (click)="copy()">
        Copy
      </button>

    </div>

    <div class="d-flex bg-light p-3">
      <div class="word-break">
        {{token}}
      </div>
    </div>
  </ng-container>

</div>

<div mat-dialog-actions [align]="'end'">

  <button
    mat-button
    color="primary"
    mat-dialog-close>
    Close
  </button>

  <button
    mat-flat-button
    color="primary"
    (click)="generateToken()">
    Generate token
  </button>

</div>
