<app-header></app-header>
<style>
@media (max-width: 991px) {
.container-fluid{
  padding:10px !important;
  padding-left:10px !important;
 }
}
 </style>
<div class="container-fluid fullPage light" style="padding:20px; padding-left:100px; ">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
